import cl from './../style/tariffSEO.module.css'

let inititalState = {
    firstColumn: {
        title: 'Тарифы на продвижение сайтов',
        text: 'Стоимость SEO продвижения рассчитывается индивидуально и зависит от\n' + 'конкурентности\n' + 'вашей ниши и количества поисковых фраз',
        cases: [{
            color: cl.red,
            title: 'Начальный',
            list: ['50 поисковых фраз', '1 регион', 'Ежемесячная отчетность', 'Рекомендации по доработке'],
            price: 'от 20 000 руб.',
        }, {
            color: cl.green,
            title: 'Оптимальный',
            list: ['150 поисковых фраз', '2-3 региона', 'Ежемесячная отчетность', 'Рекомендации по доработке'],
            price: 'от 40 000 руб.',
        }, {
            color: cl.blue,
            title: 'Расширенный',
            list: ['500 поисковых фраз', 'По России', 'Ежемесячная отчетность', 'Рекомендации по доработке'],
            price: 'от 60 000 руб.',
        },]
    }, secondColumn: {
        titleText: 'Также мы предлагаем следующие виды продвижения', cases: [{
            color: cl.yellow,
            title: 'С оплатой за трафик',
            list: 'Отлично подходит для интернет-магазинов,' + ' многостраничных сайтов, информационных порталов',
            price: 'от 1 руб. за переход.',
        }, {
            color: cl.gray,
            title: 'С оплатой за лиды',
            list: ['50 поисковых фраз', 'Один регион', 'Ежемесячная отчетность', 'Рекомендации по доработке'],
            price: 'от 200 руб. за лид.',
        },]
    }, mobileCreate: {
        title: 'Стоимость разработки мобильных приложений', cases: [{
            color: cl.red,
            title: 'Типовой',
            list: ['Каталог товаров или услуг', 'Сделать заказ', 'Новости и акции', 'Обратная связь', 'Контакты',],
            price: 'от 1 000 000 руб.',
        }, {
            color: cl.green,
            title: 'Бизнес',
            list: ['Программа лояльности', 'Push уведомления', 'Серверная часть', 'Панель управления', 'Чаты'],
            price: 'от 2 000 000 руб.',
        }, {
            color: cl.blue,
            title: 'Кастом',
            list: ['Автоматизация бизнес-процессов', 'Сложные интеграции', 'Маркетплейсы', 'Корпоративные решения', 'Smart TV, IoT, VR \\ AR'],
            price: 'от 2 000 000 руб.',
        },]
    }, CRMSystem: {
        title: 'Стоимость разработки CRM-систем ', cases: [{
            color: cl.red,
            title: 'Типовой',
            list: ['Типовой дизайн', 'Только типовые БП', 'Только типовые отчеты', 'Возможны незначительные доработки',],
            price: 'от 200 000 руб.',
        }, {
            color: cl.green,
            title: 'Бизнес',
            list: ['Интерфейс проектируется', 'Интеграции нового функционала выбранной CRM по желанию', 'Бизнес-процессы', 'Отчеты',],
            price: 'от 300 000 руб.',
        }, {
            color: cl.blue,
            title: 'Кастом',
            list: ['Уникальный дизайн интерфейса', 'Любые интеграции в необходимом объеме', 'Любой функционал по желанию', 'Любая аналитика',],
            price: 'от 500 000 руб.',
        },]
    },
}

export function tarrifSEORedux(state = inititalState, action) {
    return state
}