import React from 'react';
import MainTItem from './mainP/MainTItem';
import cl from '../style/MainTariff.module.css';
import {connect} from "react-redux/lib";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Swiper from "swiper";
import {Pagination} from "swiper";
import postRequest from "../redux/requests";



const TariffsListItem = props =>{
  


    return (
        <div className=''>
        </div>
    )
}

