import React from "react";
import cl from './../../../style/genericServStyle/MarketplaceSeoText.module.css';

const MarketplaceSeoText = ()=>{
    return (
        <section className={cl.textSection}>
            <div className="container">
                <div className={cl.textCont}>
                    <p className={cl.textTop}>Размещение на маркетплейсах – отличная возможность получить дополнительный канал трафика, расширить круг целевой аудитории и увеличить прибыль от бизнеса. Однако у подобных платформ есть один весомый недостаток. Это высокая конкуренция. Чтобы успешно конкурировать с сотнями продавцов, которые предлагают похожие товары, нужно правильно продвигаться.<br/><br/>На маркетплейсах необходимо не такое продвижение, как в интернет-магазинах. Здесь другая рекламная коммуникация и структура компаний. На что следует обращать внимание продавцу и как оказаться в числе лидеров площадки – даем конкретные рекомендации в статье.</p>
                    <h2 className={cl.title}>Как успешно продвигаться на маркетплейсе: ключевые правила</h2>
                    <p className={cl.descr}>Продажи на маркетплейсах во многом зависят от того, как часто потенциальные покупатели видят страницу с предложением конкретного продавца. До последних страниц выдачи мало кто доходит, а значит – вероятность получения заказов в таком случае сильно снижается.<br/><br/>Уникальные системы ранжирования и своя поисковая выдача, похожая на ту, которой мы привыкли пользоваться в «Яндекс» и Google, есть у каждого маркетплейса. Чтобы быть в числе лидеров, нужно постоянно работать над рейтингом на платформе.</p>
                </div>
            </div>
        </section>
    )
}

export default MarketplaceSeoText